.btn-bg-red {
    background-image: url("./img/btnredbg.png");
}

.btn-bg-yellow {

    background-image: url("./img/btnyellowbg.png");
}

.btn-bg-green {
    background-image: url("./img/btngreenbg.png");
}

.btn-bg-blue {
    background-image: url("./img/btnbluebg.png");
}

.btn-bg-orange {

    background-image: url("./img/btnorangebg.png");
}

.btn-bg-aqua {
    background-image: url("./img/btnaquabg.png");
}

.listing-red {
    background: rgb(252, 35, 67);
    background: linear-gradient(90deg, rgba(252, 35, 67, 1) 0%, rgba(253, 88, 112, 1) 100%);
}

.listing-yellow {
    background: rgb(241, 193, 23);
    background: linear-gradient(90deg, rgba(241, 193, 23, 1) 0%, rgba(251, 224, 129, 1) 100%);
}

.listing-green {
    background: rgb(93, 207, 163);
    background: linear-gradient(90deg, rgba(93, 207, 163, 1) 0%, rgba(149, 223, 195, 1) 100%);
}

.listing-blue {
    background: rgb(35, 98, 255);
    background: linear-gradient(90deg, rgba(35, 98, 255, 1) 0%, rgba(87, 135, 255, 1) 100%);
}

.listing-orange {
    background: rgb(247, 73, 12);
    background: linear-gradient(90deg, rgba(247, 73, 12, 1) 0%, rgba(249, 111, 62, 1) 100%);
}

.listing-aqua {
    background: rgb(105, 204, 238);
    background: linear-gradient(90deg, rgba(105, 204, 238, 1) 0%, rgba(149, 220, 243, 1) 100%);
}

.bg-card-red {
    background-color: #FFF6F8;
}

.bg-card-yellow {
    background-color: #FFFDF6;
}

.bg-card-green {
    background-color: #F8FDFB;
}

.bg-card-blue {
    background-color: #F6F9FF
}

.bg-card-orange {
    background-color: #FFF7F4;
}

.bg-card-aqua {
    background-color: #F9FDFF;
}